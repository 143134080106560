@import "../styles/common";

.Jumbotron {
  position: relative;
  height: 30vh;
  min-height: 330px;
  display: inline-flex;
  width: 100%;
  align-items: center;

  @media screen and (max-height: 320px) {
    // iPhone landscape
    height: 280px;
    min-height: 0;
  }

  @media screen and (max-height: 460px) {
    // probably some phones out there
    height: 430px;
  }
}

.Jumbotron-title {
  @include responsive(
    "font-size",
    (
      xs: 2rem,
      sm: 2.6rem,
      md: 3.3rem,
      lg: 4rem
    )
  );

  color: white;
  letter-spacing: calc(-1px / 2);
  display: block;
  margin-left: auto;
  margin-right: auto;
  line-height: 1;
}
