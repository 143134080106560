@import "../styles/common";

.service-link {
  transition: all $default-transition;
  border-top: 1px solid $border-color;
  border-bottom: 1px solid $border-color;
  background: none;
  align-items: center;
  cursor: pointer;
  margin-bottom: -1px;

  @include media-breakpoint-down(lg) {
    .row .col,
    [class*="col-"] {
      padding-left: calc($grid-gutter-width / 3);
      padding-right: calc($grid-gutter-width / 3);
    }
  }

  &:hover {
    background: transparentize($blue, 0.9);

    @media (prefers-color-scheme: dark) {
      background: transparentize($blue, 0.4);
    }

    &,
    * {
      text-decoration: none !important;
    }
  }

  @include media-breakpoint-up(lg) {
    img.responsive-img {
      max-width: 200px;
    }
  }
}

.service-link-icon {
  @include responsive(
    "font-size",
    (
      xs: 2.5rem,
      md: 3rem,
      lg: 4rem
    )
  );
}

.service-link-title {
  margin: 0;
}

.service-link-tagline {
  line-height: 1.1;
}

.service-link-arrow {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-align: right;
  transition: transform $default-transition;
}

.service-link:hover {
  > .service-link-arrow {
    transform: translateX(-5px);
  }
}
