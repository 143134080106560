@import "../styles/common";

.divider {
  @include media-breakpoint-up(xl) {
    max-width: calc(#{map-get($container-max-widths, xl)} + 3rem);
  }

  @extend .gradient-blue-teal-green;

  max-width: 80%;
  height: 3px;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
}

.vertical-divider {
  flex: 0 0 0;
  border-start: 1px solid white;
  margin: 2rem -1px 2rem 0;
}
