@import "../styles/common";

.project-panel {
  > img {
    @include responsive(
      ("height", "width"),
      (
        xs: 80px,
        md: 110px
      )
    );
  }
}
