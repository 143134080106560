@import "../styles/common";

section.wallpaper-section {
  height: auto;
  min-height: 32vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  position: relative;
  overflow: hidden;

  border-radius: inherit;

  > .divider {
    @include responsive(
      "height",
      (
        xs: 6px,
        md: 10px,
        lg: 10px,
      )
    );

    max-width: none;
    position: absolute;
    top: 0;
    bottom: auto;
    right: 0;
    left: 0;
    z-index: 1;
    border-width: 0;

    &.bottom {
      top: auto;
      bottom: 0;
    }
  }
}
