@import "variables.scss";

$gradient-rainbow: linear-gradient(
  111deg,
  $dark-blue 0%,
  $blue 15%,
  $teal 35%,
  $green 65%,
  $green 80%,
  $orange 105%
);
$gradient-no-orange: linear-gradient(
  111deg,
  $green 10%,
  $teal 40%,
  $blue 80%,
  $dark-blue 100%
);
$gradient-blue-teal-green: linear-gradient(
  135deg,
  $green -35%,
  $teal 30%,
  $blue
);

$gradients: (
  "rainbow": $gradient-rainbow,
  "no-orange": $gradient-no-orange,
  "blue-teal-green": $gradient-blue-teal-green,
);

%gradient-rainbow {
  background-color: $primary;
  background: $gradient-rainbow;
}

%gradient-no-orange {
  background-color: $primary;
  background: $gradient-no-orange;
}

.gradient-blue-teal-green {
  background: $gradient-blue-teal-green;
}

.gradient-green-dark-green {
  background: linear-gradient(
    -125deg,
    $dark-green -40%,
    $green 50%,
    $light-green 130%
  );
}

.gradient-green-teal {
  background: linear-gradient(111deg, $green -50%, $teal 125%);
}

.gradient-lightgreen-green-teal {
  background: linear-gradient(
    111deg,
    $light-green -15%,
    $green 40%,
    $teal 115%
  );
}

.gradient-teal-blue {
  background: linear-gradient(111deg, $teal -15%, $blue 115%);
}

.gradient-aqua-blue {
  background: linear-gradient(111deg, $aqua -15%, $blue 115%);
}

.gradient-green-teal-aqua {
  background: linear-gradient(111deg, $green -15%, $teal 70%, $aqua 150%);
}

.gradient-teal-green-orange {
  background: linear-gradient(111deg, $teal -35%, $green 30%, $orange 110%);
}

.gradient-none {
  background: none;
}
