@import "../styles/common";

section {
  position: relative;

  &,
  .section-regular {
    @include responsive(
      ("margin-top", "margin-bottom"),
      (
        xs: 1rem,
        md: 2.5rem,
        lg: 4rem,
      )
    );
    @include responsive(
      ("padding-top", "padding-bottom"),
      (
        xs: 2rem,
        md: 3.5rem,
        lg: 6.5rem,
      )
    );
  }

  &.section-small {
    @include responsive(
      ("margin-top", "margin-bottom"),
      (
        xs: 1rem,
        md: 1.5rem,
        lg: 2rem,
      )
    );
    @include responsive(
      ("padding-top", "padding-bottom"),
      (
        xs: 2rem,
        md: 3rem,
        lg: 4rem,
      )
    );
  }

  &.section-big {
    @include responsive(
      ("margin-top", "margin-bottom"),
      (
        xs: 2rem,
        md: 3rem,
        lg: 6rem,
      )
    );
    @include responsive(
      ("padding-top", "padding-bottom"),
      (
        xs: 6rem,
        md: 8rem,
        lg: 13rem,
      )
    );

    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
  }

  &.section-huge {
    @extend .section-big;

    min-height: 80vh;
  }
}

.step-section {
  &::before {
    // @include media-breakpoint-up("lg") {
    //   display: none;
    // }
    $line-width: 3px;
    content: " ";
    position: absolute;
    top: 0;
    bottom: 0;
    border-left: $line-width solid;
    @include responsive(
      "left",
      (
        xs: calc((-75px / 2) - 15px + (#{$line-width} / 2)),
        sm: calc((-100px / 2) - 15px + (#{$line-width} / 2)),
      )
    );
    @include responsive(
      ("margin-top"),
      (
        xs: calc(2rem + 75px + #{$spacer}),
        sm: calc(2rem + 100px + #{$spacer}),
        md: calc(3rem + 100px + #{$spacer}),
        lg: calc(4rem + 100px + #{$spacer}),
      )
    );
    @include responsive(
      ("margin-bottom"),
      (
        xs: calc(2rem + #{$spacer}),
        md: calc(3rem + #{$spacer}),
        lg: calc(4rem + #{$spacer}),
      )
    );
  }

  @each $color, $value in $theme-colors {
    &.step-section-#{$color} {
      &::before {
        border-color: $value;
      }
    }
  }

  .big-icon {
    @include responsive(
      "max-width",
      (
        xs: 75px,
        sm: 100px,
      )
    );
  }
}
