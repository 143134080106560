@import "../styles/common";

.hero {
  @include responsive(
    ("padding-top", "padding-bottom"),
    (
      xs: 2rem,
      sm: 3rem,
      lg: 4rem,
    )
  );

  margin-top: 1rem;

  .title-wrapper {
    > .title {
      @include responsive(
        "font-size",
        (
          xs: 2.5rem,
          sm: 4rem,
          md: 5rem,
          lg: 6rem,
        )
      );
      @include responsive(
        "margin-bottom",
        (
          xs: 0.5em,
          md: 0,
        )
      );

      font-weight: 400;
      line-height: 0.98;
    }
  }

  .lead {
    @include responsive(
      "font-size",
      (
        xs: 1.15rem,
        md: 1.3rem,
        lg: 1.5rem,
      )
    );
  }
}
