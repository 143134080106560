// Imports

@import "common";
@import "~bootstrap/scss/bootstrap.scss";
@import "font-awesome";
@import "typography";
@import "dark-theme";
@import "gradients";

// Global defaults

.text-container {
  @include make-container();
  @include responsive("max-width", $text-container-max-widths);
}

.cursor-pointer {
  cursor: pointer;
}

.fade-in {
  @keyframes fade-in {
    from {
      opacity: 0;
      transform: translateY(15px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  animation-name: fade-in;
  animation-duration: 0.3s;
}

.center{
  text-align: center;
  display: block;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 100%;
}

body {
  --bs-body-font-weight: 200;
}