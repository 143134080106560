@import url("//fonts.googleapis.com/css?family=Fira+Sans+Extra+Condensed:300,500|Nunito+Sans:200,400,600|Material+Icons|Material+Icons+Outlined");

body {
  @include responsive(
    "font-size",
    (
      xs: 1rem,
      md: 1.15rem,
      lg: 1.25rem,
    )
  );
}

.title-font {
  font-family: "Fira Sans Extra Condensed", $font-family-sans-serif;
  font-weight: 500 !important;
}

.subtitle-font {
  font-family: "Fira Sans Extra Condensed", $font-family-sans-serif;
  font-weight: 300 !important;
}

h1,
.h1 {
  @include responsive(
    "font-size",
    (
      xs: 2rem,
      md: 2.7rem,
      lg: 3.4rem,
    )
  );

  font-family: "Fira Sans Extra Condensed", $font-family-sans-serif;
  font-weight: 500 !important;
  margin-bottom: 0.5em;
}

h2,
.h2 {
  font-family: "Fira Sans Extra Condensed", $font-family-sans-serif;
  font-weight: 500 !important;

  @include responsive(
    "font-size",
    (
      xs: 1.8rem,
      md: 2.1rem,
      lg: 2.5rem,
    )
  );
}

h3,
.h3 {
  font-family: "Fira Sans Extra Condensed", $font-family-sans-serif;
  font-weight: 500 !important;

  @include responsive(
    "font-size",
    (
      xs: 1.35rem,
      md: 1.6rem,
      lg: 1.8rem,
    )
  );

  font-weight: $fw-bold;
}

h4,
.h4 {
  font-size: 1.2rem;
}

.subtitle {
  font-size: 0.9rem;
  font-family: $font-family-sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-weight: 100;
  margin-top: 0;
  // margin-bottom: 0.8em;
}

h1.giant-responsive {
  @include responsive(
    "font-size",
    (
      xs: 3rem,
      md: 4.5rem,
      lg: 5.5rem,
    )
  );
}

.lead {
  @include responsive(
    "font-size",
    (
      xs: 1.15rem,
      md: 1.25rem,
      lg: 1.35rem,
    )
  );

  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}

a {
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

.pretty-link {
  color: $blue;
  text-decoration: none;
  border-bottom: 1px solid transparentize($blue, 0.5);
  transition: border-color $default-transition, color $default-transition;
  cursor: pointer;

  @each $name, $color in $theme-colors {
    .text-#{$name} & {
      color: $color;
      border-bottom-color: $color;

      &:hover {
        color: transparentize($color, 0.25);
        color: map-get($theme-colors, "transparent-" + $name);
      }
    }
  }

  &:hover {
    border-color: transparent;
    color: $aqua;
  }
}

strong,
b,
.strong,
.fw-bold {
  font-weight: 600;
}

@each $name, $color in $theme-colors {
  .hover-text-#{$name} {
    &:hover {
      color: $color !important;
    }
  }

  .text-#{$name} {
    .text-transparentize {
      background-color: "transparent-" + $name;
      color: map-get($theme-colors, "transparent-" + $name);
    }
  }
}

ul,
ol {
  &.separated {
    > li {
      @extend .mb-3;
    }
  }
}
