@import "../styles/common";

section.wallpaper-section.insights-quote {
  margin-bottom: -4rem;
  background-color: $orange;
  min-height: 0;
  z-index: 100;
  @include media-breakpoint-up('xl') {
    margin-bottom: 0;
    // margin-left: -18rem;
    max-width: 65vw;
    margin-left: auto;
    margin-right: auto;
  }
  padding: 2rem 1rem;

  .quote-img {
    @include responsive('max-width',(
      '': 70px,
      sm: 86px,
      md: 100px,
      lg: 150px
    ))
  }
}