@import "../styles/common";

// Footer
footer {
  @include media-breakpoint-up(md) {
    margin-top: 60px;
  }

  background-color: $body-color;
  margin-top: 30px;
  padding-top: 50px;
  padding-bottom: 30px;
  color: $footer-link-color;
  position: relative;

  .pretty-link {
    text-decoration: none;
    border-bottom: 0;
    color: $footer-link-color;

    &:hover {
      color: $footer-link-hover;
    }
  }

  &::before {
    @extend %gradient-rainbow;

    content: "";
    left: 0;
    right: 0;
    top: 0;
    height: 20px;
    position: absolute;
    z-index: 1;
  }
}

.sitemap {
  > .pretty-link {
    display: block;

    &:after {
      font-family: "Material Icons";
      content: "keyboard_arrow_right";
      font-size: 1rem;
      line-height: 1;
      transform: translate(0.3rem, 15%);
      display: inline-block;
      font-weight: normal;
      -webkit-font-smoothing: antialiased;
      -webkit-font-feature-settings: "liga";
      transition: transform $default-transition;
    }

    &:hover {
      &:after {
        transform: translate(0, 15%);
      }
    }
  }
}

.social-media-link {
  font-size: 34px;
  margin-right: 15px;
  text-decoration: none;

  i {
    transition: color $default-transition;
    color: $footer-link-color;
  }

  &:hover {
    text-decoration: none;

    i {
      color: $footer-link-hover;
    }
  }
}

.contact-footer-block + footer {
  margin-top: 0;
}
