@import "../styles/common";

@each $name, $color in $theme-colors {
  .hover-transparent-#{$name} {
    transition: background-color 0.15s linear;
    will-change: background-color;
    &:hover {
      background-color: map-get(
        $theme-colors,
        "transparent-" + $name
      ) !important;
    }
  }
}
