@import "../styles/common";

.service-block {
  @each $name, $color in $theme-colors {
    &.service-block-#{$name} {
      > .color-block {
        background: map-get($theme-colors, "transparent-" + $name);
        color: $color !important;
        transition: background-color 0.15s linear;
        will-change: background-color;
        position: relative;

        * {
          transition: color 0.15s linear;
          will-change: color;
        }

        .icon-white,
        .icon-color {
          transition: opacity 0.15s linear;
          will-change: opacity;
        }

        .icon-white {
          opacity: 0;
        }
        .icon-color {
          opacity: 1;
          top: 0;
          left: 0;
        }

        &:hover {
          background-color: $color;

          * {
            color: $white !important;
          }

          .icon-white {
            opacity: 1;
          }
          .icon-color {
            opacity: 0;
          }
        }
      }
    }
  }
}
