@import "../styles/common.scss";

.navbar-brand {
  align-self: center;
  margin: 5px 5px 5px 0;
  padding: 0;
  width: 200px;
  height: 51px;
  flex: 0 0 201px;
  background: url("../assets/Landscape-logo-200px.png");
  background-size: 100% !important;
  background-repeat: no-repeat !important;
  background-position: center !important;

  @include retina-display {
    background: url("../assets/Landscape-logo-200px@2x.png");
  }
}

.navbar-gradient-bottom {
  @extend %gradient-rainbow;

  height: 2px;
  width: 100%;
  opacity: 0.9;
}

.navbar-toggler {
  border: 0;
  display: flex;
  align-items: center;

  .material-icons {
    transition: opacity $default-transition;
    will-change: opacity;
  }
}

.nav-link {
  position: relative;
  will-change: background, background-clip;

  &.active {
    text-decoration: underline;
    color: $navbar-light-active-color !important;
  }

  @include media-breakpoint-up(lg) {
    &.active {
      text-decoration: none;
    }

    &:after {
      content: " ";
      height: 1px;
      position: absolute;
      bottom: 0.9rem;
      left: 50%;
      right: 50%;
      background-color: transparent;
      opacity: 0;
      transition: left ease-out 0.2s, right ease-out 0.2s;
      will-change: left, right;
    }

    &.active:after {
      opacity: 0.5;
      bottom: 0.7rem;
      background-color: $navbar-light-active-color !important;
      left: $navbar-nav-link-padding-x;
      right: $navbar-nav-link-padding-x;
    }
  }
}

.nav-link:hover {
  background: linear-gradient(90deg, $dark-blue 20%, $teal 80%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.language-link {
  $language-link-color: $green;
  $language-link-padding-x: calc($navbar-nav-link-padding-x / 3);

  opacity: 0.86;

  @include media-breakpoint-up(lg) {
    font-size: 0.86rem;
    padding-left: $language-link-padding-x !important;
    padding-right: $language-link-padding-x !important;

    &.nav-link.active {
      color: $language-link-color !important;
      &:after {
        opacity: 0.5;
        bottom: 0.7rem;
        background-color: $language-link-color !important;
        left: $language-link-padding-x;
        right: $language-link-padding-x;
      }
    }
  }
}

.navbar-dark {
  background-color: $dark-theme-background;
}

.navbar-light {
  background-color: $body-bg;
}
