// NOTE: this is only a subset, generated with https://icomoon.io/

@font-face {
  font-family: "font-awesome";
  src: url("./fonts/font-awesome.eot?xp9ple");
  src: url("./fonts/font-awesome.eot?xp9ple#iefix") format("embedded-opentype"),
    url("./fonts/font-awesome.ttf?xp9ple") format("truetype"),
    url("./fonts/font-awesome.woff?xp9ple") format("woff"),
    url("./fonts/font-awesome.svg?xp9ple#font-awesome") format("svg");
  font-weight: normal;
  font-style: normal;
}

.fa {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "font-awesome" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fa-twitter-square:before {
  content: "\f081";
}

.fa-facebook-square:before {
  content: "\f082";
}

.fa-linkedin-square:before {
  content: "\f08c";
}

.fa-linkedin-square-blue:before {
  content: "\f08c";
  color: #0072b1;
}
