@media not all and (prefers-color-scheme: dark) {
  .navbar-dark {
    display: none;
  }
}

@media (prefers-color-scheme: dark) {
  body {
    background-color: $dark-theme-background;
    color: $dark-theme-font-color;
    --bs-body-font-weight: 400 !important;
  }

  a {
    color: $dark-theme-font-color;
  }
  .text-white {
    opacity: 0.9;
  }

  .nav-link {
    color: $dark-theme-font-color !important;
  }

  .navbar-light {
    display: none;
  }

  .navbar-brand {
    background: url("../assets/Landscape-logo-dark-mode-200px.png") !important;

    @include retina-display {
      background: url("../assets/Landscape-logo-dark-mode-200px@2x.png") !important;
    }
  }

  hr {
    border-top: 1px solid transparentize($white, 0.7);
  }

  .client-logo:not(.color) {
    filter: invert(1);
  }

  .bg-white,
  [class*="bg-light"],
  [class*="bg-transparent"],
  .bg-primarly-light {
    color: $font-color;
  }

  .text-muted {
    color: $dark-theme-font-color !important;
    opacity: 0.5;
  }

  .modal-content {
    background: $dark-theme-background;
  }

  .card {
    --bs-card-color: #dfdfdf;
    --bs-card-bg: #191919;
    --bs-card-title-color: #dfdfdf;
  }
}