$enable-rounded: false;

// Colors

$orange: #efac4d;
$green: #89c332;
$teal: #0fb8a4;
$aqua: #1fdbdb;
$blue: #0aa3db;
$dark-blue: #1475a9;
$dark-green: #68991e;
$light-blue: hsl(204, 86%, 86%);
$light-green: hsl(81, 80%, 79%);
$grey: hsl(220, 2%, 64%);
$alt-grey: hsl(220, 2%, 38%);

$transparent-green: #e7f2d8;
$transparent-teal: #d6f0eb;
$transparent-aqua: #def2f4;
$transparent-blue: #d1edf8;
$transparent-dark-blue: #bed9e6;
$transparent-orange: #fcefdc;

$primary: $green;
$primary-light: $light-green;
$signal-1: $dark-blue;
$signal-1-light: $light-blue;
$signal-2: $teal;

$brand-primary: $primary;

$font-color: hsl(210, 15%, 25%);
$body-color: $font-color;

$black: #000;
$white: #fff;

$alert-yellow: #e9e700;
$alert-red: #ff4f71;
$alert-green: #4bee81;
$alert-blue: #00d5f0;
$pretty-pink: #e99ed2;
$bomb-orange: #e8980c;

$dark-theme-background: #191919;
$dark-theme-font-color: #dfdfdf;

$theme-colors: (
  "white": $white,
  "orange": $orange,
  "green": $green,
  "teal": $teal,
  "aqua": $aqua,
  "blue": $blue,
  "dark-blue": $dark-blue,
  "dark-green": $dark-green,
  "light-blue": $light-blue,
  "light-green": $light-green,
  "transparent-green": $transparent-green,
  "transparent-teal": $transparent-teal,
  "transparent-aqua": $transparent-aqua,
  "transparent-blue": $transparent-blue,
  "transparent-dark-blue": $transparent-dark-blue,
  "transparent-orange": $transparent-orange,
  "grey": $grey,
  "alt-grey": $alt-grey,
  "primary": $primary,
  "primary-light": $primary-light,
  "signal-1-light": $signal-1-light,
  "body": $body-color,
  "dark": $font-color,
);

$link-color: $body-color;
$link-hover: $green;
$navbar-font-color: $body-color;
$footer-link-color: rgba(255, 255, 255, 0.7);
$footer-link-hover: $white;

// Sizing variables

$text-container-max-widths: (
  sm: 80vw,
  lg: 50rem,
);

$blog-container-max-widths: (
  sm: 540px,
  lg: 40rem,
);

// Fonts

$font-family-sans-serif: "Nunito Sans", -apple-system, system-ui,
  BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
$font-size-base: 1.15rem;
$line-height-base: 1.6;
$fw-normal: 200;
$fw-bold: 700;

$headings-line-height: 1;
$display-line-height: 0.86em;
$input-btn-font-size: 1rem;
$input-btn-font-size-lg: 1.15rem;

// Navbar

$navbar-padding-y: 0;
$nav-link-padding: 1em;
$navbar-light-toggler-border: transparent;
$navbar-light-color: transparentize($body-color, 0.2);
$navbar-light-hover-color: $teal;
$navbar-light-active-color: $dark-blue;

$dropdown-link-active-bg: transparentize($green, 0.7);

// Jumbotron

$jumbotron-bg: transparent;
$jumbotron-padding: 0;

// Utilities

$default-transition: 0.25s ease-out;
