@mixin retina-display {
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    @content;
  }
}

@mixin place-at-center {
  // of a 'relative' or 'fixed' parent container
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin place-at-vertical-center {
  // of a 'relative' or 'fixed' parent container
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

@mixin place-at-horizontal-center {
  // of a 'relative' or 'fixed' parent container
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

@mixin responsive($list, $map) {
  @each $size, $val in $map {
    @include media-breakpoint-up($size) {
      @each $feature in $list {
        #{$feature}: $val;
      }
    }
  }
}
