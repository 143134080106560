@import "../styles/common";

.collapsibles {
  .collapsible {
    // border: 1px solid red;

    .collapsible-header {
      .indicator-text {
        visibility: hidden;
        color: $grey;
      }

      h3 {
        margin-bottom: 0;
        color: $blue;
        font-family: "Fira Sans Extra Condensed", $font-family-sans-serif;
        font-weight: 500 !important;
      }

      .subtitle-font {
        color: $grey  !important;
      }
    }

    .collapsible-header:hover {
      .indicator-text {
        visibility: visible;
      }
    }
  }
}