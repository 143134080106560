@import "../styles/common";

.background-image-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  overflow: hidden;

  > .background-image {
    display: block;
    min-height: 101%;
    @include media-breakpoint-up(lg) {
      min-height: none;
      min-width: 101%;
    }
    filter: brightness(55%);

    @include place-at-center();
  }
}
