@import "../styles/common";

.Button {
  margin-bottom: $spacer;
  margin-top: $spacer;

  > .arrow {
    display: inline-block;
    position: relative;
    width: 20px;
    height: calc(1em - 6px);

    > i {
      @include place-at-center;

      position: absolute;
      right: 0.1em;
      display: inline-block;
      font-size: 20px;
      line-height: inherit;
      transition: right $default-transition;
    }
  }

  &:hover {
    > .arrow {
      > i {
        right: -0.2em;
      }
    }
  }

  &.btn-white,
  &.btn-outline-white {
    &:hover {
      background-color: transparentize($white, 0.2);
    }
  }
}
